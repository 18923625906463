import Layout from '@/layouts/layout';
import { deliveryService, deliveryApiClient } from '@/lib/api';
import { nextPathToUrl, urlToNextPath } from '@/lib/helpers';
import { UmbracoGrid } from '@ingeniorforeningen/figurine-cms';
import {
  IApiContentResponseModel,
  PagedIApiContentResponseModel,
  IApiMediaWithCropsResponseModel,
} from '@ingeniorforeningen/umbraco-client';

const Slug = ({
  page,
  image,
  headerNode,
}: {
  page: IApiContentResponseModel;
  image?: IApiMediaWithCropsResponseModel;
  headerNode: IApiContentResponseModel;
  footerNode: IApiContentResponseModel;
}) => {
  const { properties } = page;
  return (
    <Layout headerData={headerNode} page={page} image={image}>
      <UmbracoGrid data={properties?.components} my="xl" py="xl" />
    </Layout>
  );
};

export async function getStaticPaths({
  preview,
  locales,
}: {
  preview: boolean;
  locales: string[];
}) {
  const params = (
    await Promise.all(
      locales.map(async (locale) => {
        const conf = await deliveryService
          .getContent({
            fetch: `descendants:${process.env.NEXT_PUBLIC_CONFERENCE_ROOT_GUID}`,
            accept_Language: locale,
            api_Key: process.env.NEXT_PUBLIC_UMBRACO_API_KEY,
            preview: preview,
            take: 1000,
          })
          .then((r) => r.toJSON() as PagedIApiContentResponseModel);

        return conf.items
          .filter((page) => page.route)
          .map((page) => ({
            locale,
            params: {
              slug: urlToNextPath(page.route!.path || '/', locale),
            },
          }));
      }),
    )
  ).flat(1);

  if (params.length < 1) {
    console.warn('No conference pages found. This should not happen');
  } else {
    console.log(
      'generating html pages for: ' + params.map((p) => '(' + p.locale + ':' + p.params.slug + ')'),
    );
  }
  return {
    paths: params,
    fallback: 'blocking',
  };
}

export async function getStaticProps({
  params,
  preview,
  locale,
}: {
  params: { slug: string[] };
  preview: boolean;
  locale: string;
}) {
  const slug = nextPathToUrl(params.slug);
  // Validate slug before using it
  if (!slug) {
    return { notFound: true };
  }

  const { expand, accept_Language, api_Key, start_Item } = {
    expand:
      'properties[components[properties[person[properties[image]],programEntries[properties[speakers[properties[image]]]],image]]]',
    accept_Language: locale,
    api_Key: process.env.NEXT_PUBLIC_UMBRACO_API_KEY,
    start_Item: process.env.NEXT_PUBLIC_CONFERENCE_ROOT_GUID,
  };

  let page: IApiContentResponseModel | null = null;
  let header: IApiContentResponseModel | null = null;
  let image: IApiMediaWithCropsResponseModel | null = null;

  page = (await deliveryService
    .getContentByPath({
      path: slug,
      expand,
      accept_Language,
      api_Key,
      preview,
      start_Item,
    })
    .then((r) => r.toJSON() as IApiContentResponseModel)
    .catch((e) => {
      console.log(`failed retrieving page ${slug}: `, JSON.stringify(e));
      return null;
    })) as IApiContentResponseModel | null;

  header = params.slug[0]
    ? ((await deliveryService
        .getContentByPath({ path: params.slug[0], expand, accept_Language, api_Key, preview })
        .then((r) => r.toJSON() as IApiContentResponseModel)
        .catch((e) => {
          console.log(`failed retrieving header ${slug}: `, JSON.stringify(e));
          return null;
        })) as IApiContentResponseModel | null)
    : null;

  image = page?.properties?.image?.[0]?.id
    ? ((await deliveryApiClient
        .getMediaItemById2_0(page.properties.image[0].id, 'all', undefined, api_Key)
        .then((r) => JSON.parse(JSON.stringify(r)) as IApiMediaWithCropsResponseModel) // TODO: Remove when umbraco-client is updated to to use null and not undefined
        .catch((e) => {
          console.log(`failed retrieving image ${slug}: `, JSON.stringify(e));
          return null;
        })) as IApiMediaWithCropsResponseModel | null)
    : null;

  return {
    props: {
      page: page,
      headerNode: header,
      image: image ? JSON.parse(JSON.stringify(image)) : null,
      preview: preview || null,
    },
    revalidate: 60,
    notFound: !page,
  };
}

export default Slug;
